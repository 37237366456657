import React from 'react';

function TextInput({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
}) {
  return (
    <div className="form-input form-text-input">
      {label && (
        <label htmlFor={name} className="form-input__label">
          {label}{required ? ' *' : ''}
        </label>
      )}

      <input
        type="text"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        className='form-input__field'
      />
    </div>
  );
}

export default TextInput;