import React, { useMemo, useTransition } from 'react';
import CheckboxGroup from '../common/CheckboxGroup';
import TextInput from '../common/TextInput';
import { useTranslation } from 'react-i18next';

export default function MedicalConditionsForm({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();
  const options = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('mc_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('medical_conditions_form_title')}</h2>
      <CheckboxGroup
        title={t('title_for_mc_group')}
        options={options}
        selectedValues={data}
        onChange={onChange}
      />
      <TextInput
          label={t('other_mc')}
          name="other_mc"
          value={data.other_mc}
          onChange={onChange}
        />
    </div>
  );
}
