import React from 'react';

function DateInput({
  label,        // The label text describing the field
  name,         // The 'name' attribute for the input
  value,        // The current date value (in ISO format, e.g., "2025-01-17")
  onChange,     // A function to handle input changes
  required,     // Optional: if the field is required
  min,          // Optional: minimum date (YYYY-MM-DD)
  max,          // Optional: maximum date (YYYY-MM-DD)
  placeholder,  // Optional placeholder text
}) {
  return (
    <div className="form-input form-date-input">
      {label && (
        <label htmlFor={name} className="form-input__label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}

      <input
        type="date"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        placeholder={placeholder}
        className="form-input__field"
      />
    </div>
  );
}

export default DateInput;