import React from 'react';
import CheckboxItem from './CheckboxItem';

function DualCheckboxGroup({ title, option1, option2, onChange }) {
  const handleChange = (e) => {
    const { name, type } = e.target;
  
    const updatedEvent = {
      target: { name, type, checked: true },
    };  
    onChange(updatedEvent);
  
    const otherOption = name === option1.name ? option2.name : option1.name;
    const deselectEvent = {
      target: { name: otherOption, type, checked: false },
    };
    onChange(deselectEvent);
  };

  return (
    <div className="dual-checkbox-group">
      {title && <h3 className="dual-checkbox-group__title">{title}</h3>}

      <div className="dual-checkbox-group__list">
        <CheckboxItem
          label={option1.label}
          name={option1.name}
          checked={!!option1.value}
          onChange={handleChange}
        />
        <CheckboxItem
          label={option2.label}
          name={option2.name}
          checked={!!option2.value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default DualCheckboxGroup;