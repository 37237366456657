import React, { useCallback, useRef, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import GeneralInfoForm from './facial-treatment/GeneraInfoForm';
import SignatureForm from './facial-treatment/SignatureForm';
import MedicalConditionsForm from './facial-treatment/MedicalConditionsForm';
import MedicalConditions2Form from './facial-treatment/MedicalConditions2Form';
import MedicalConditions3Form from './facial-treatment/MedicalConditions3Form';
import MedicalConditions4Form from './facial-treatment/MedicalConditions4Form';
import MedicalConditions5Form from './facial-treatment/MedicalConditions5Form';
import MedicalConditions6Form from './facial-treatment/MedicalConditions6Form';


function FacialTreatmentForm() {
  const { t, i18n } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const signatureRef = useRef(null);

  const [generalData, setGeneralData] = useState({
    name: '',
    address: '',
    sex_woman: false,
    sex_man: false,
    date: '',
    birth_date: '',
    age: '',
    telephone: '',
    email: '',
    how_you_found_us: '',
    visit_purpose: '',
    subscribe_to_email_list_yes: false,
    subscribe_to_email_list_no: false,
  });

  const [medicalConditionData, setMedicalConditionData] = useState({
    mc_acne: false,
    mc_arthritis: false,
    mc_asthma: false,
    mc_blood_diseases: false,
    mc_oncology: false,
    mc_diabetes: false,
    mc_eczema: false,
    mc_epilepsy: false,
    mc_pacemaker: false,
    mc_heart_disease: false,

    mc_herpes: false,
    mc_hepatitis: false,
    mc_hypertension: false,
    mc_hiv_aids: false,
    mc_hyperpigmentation: false,
    mc_hypopigmentation: false,
    mc_hysterectomy: false,
    mc_autoimmune: false,
    mc_insomnia: false,
    mc_keloid_scars: false,

    mc_hypotension: false,
    mc_lupus: false,
    mc_metal_implants: false,
    mc_varicose_thrombosis: false,
    mc_seizures: false,
    mc_skin_diseases: false,
    mc_seborrhea: false,
    mc_thyroid_disease: false,
    mc_warts: false,

    other_mc: ''
  });

  const [medicalCondition2Data, setMedicalCondition2Data] = useState({
    allergies_no: false,
    allergies_yes: false,
    allergies: '',
    regular_meds_list: '',
    recent_surgery_or_cosmetic_no: false,
    recent_surgery_or_cosmetic_yes: false,
    recent_surgery_or_cosmetic: '',
    pregnant_or_trying_no: false,
    pregnant_or_trying_yes: false,
    previous_facial_treatments_no: false,
    previous_facial_treatments_yes: false,
    previous_facial_treatments: '',
    clinic_visit_goals: ''
  });

  const [medicalCondition3Data, setMedicalCondition3Data] = useState({
    sc_micellar_water: false,
    sc_cleansing_foam: false,
    sc_cleansing_gel: false,
    sc_soap: false,
    sc_exfoliants: false,
    sc_toning_lotion: false,
    sc_day_cream: false,
    sc_night_cream: false,
    sc_eye_cream: false,
    sc_serums: false,
    sc_acids_retinol_vit_c: false,
    sc_masks: false,
    sc_hand_cream: false,
    sc_body_cream: false,
    sc_body_scrub: false,
  });

  const [medicalCondition4Data, setMedicalCondition4Data] = useState({
    sh_t_normal: false,
    sh_t_oily: false,
    sh_t_dry: false,
    sh_t_combination: false,
    sh_t_unknown: false,

    sh_spf_never: false,
    sh_spf_15_plus: false,
    sh_spf_30_plus: false,
    sh_spf_50_plus: false,

    sh_foundation_liquid: false,
    sh_foundation_powder: false,
    sh_foundation_cream: false,
    sh_foundation_none: false,

    sh_healing_fast: false,
    sh_healing_slow: false,
    sh_healing_scars: false,
    sh_healing_pigment: false,

    sh_bruising_yes: false,
    sh_bruising_no: false,
  });

  const [medicalCondition5Data, setMedicalCondition5Data] = useState({
    st_acne: false,
    st_black_dots: false,
    st_brittle_capillaries: false,
    st_comedones: false,
    st_hemangiomas: false,
    st_color_change: false,
    st_skin_dryness: false,
    st_eczema: false,
    st_wrinkles: false,
    st_hyperpigmentation: false,
    st_hypopigmentation: false,
    st_keloid_scars: false,
    st_milia: false,
    st_oily_skin: false,
    st_psoriasis: false,
    st_couperose: false,
    st_rosacea: false,
    st_scars: false,
    st_sensitivity: false,
    st_pigmentation: false,
    st_thin_skin: false,
    st_unwanted_hair: false,
    st_other: false,
    other_st_list: '',
  });

  const [medicalCondition6Data, setMedicalCondition6Data] = useState({
    used_acne_or_antibiotics_when: '',
    used_acne_or_antibiotics_which: '',
    used_acne_or_antibiotics_yes: false,
    used_acne_or_antibiotics_no: false,
    
    used_retin_a_or_vitamin_a_products_which: '',
    used_retin_a_or_vitamin_a_products_yes: false,
    used_retin_a_or_vitamin_a_products_no: false,

    had_botox_or_fillers_which: '',
    had_botox_or_fillers_yes: false,
    had_botox_or_fillers_no: false,
  });

  const handleInputChange = (e, setter) => {
    const { name, checked, type, value } = e.target;

    setter((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleGeneralChange = (e) => handleInputChange(e, setGeneralData);
  const handleMedicalConditionChange = (e) => handleInputChange(e, setMedicalConditionData);
  const handleMedicalCondition2Change = (e) => handleInputChange(e, setMedicalCondition2Data);
  const handleMedicalCondition3Change = (e) => handleInputChange(e, setMedicalCondition3Data);
  const handleMedicalCondition4Change = (e) => handleInputChange(e, setMedicalCondition4Data);
  const handleMedicalCondition5Change = (e) => handleInputChange(e, setMedicalCondition5Data);
  const handleMedicalCondition6Change = (e) => handleInputChange(e, setMedicalCondition6Data);

  const handleDownloadPdf = async () => {
    try {
      const existingPdfBytes = await fetch(t('facial_treatment_pdf_location'))
        .then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();

      const fontBytes = await fetch('/assets/fonts/georgia.ttf')
        .then((res) => res.arrayBuffer());
      pdfDoc.registerFontkit(fontkit);
      const customFont = await pdfDoc.embedFont(fontBytes);

      const mergedData = {
        ...generalData,
        ...medicalConditionData,
        ...medicalCondition2Data,
        ...medicalCondition3Data,
        ...medicalCondition4Data,
        ...medicalCondition5Data,
        ...medicalCondition6Data
      };
      Object.entries(mergedData).forEach(([key, value]) => {
        // Grab the field if it exists
        const field = form.getFieldMaybe(key);
        if (!field) return; // No such field in PDF, skip it

        // If it's a boolean, treat as checkbox (check or uncheck)
        if (typeof value === 'boolean') {
          value ? field.check() : field.uncheck();
        } else {
          // Otherwise, cast to string and set text
          field.setText(String(value));
        }
      });

      form.updateFieldAppearances(customFont);

      // sign the document
      const signatureDataURL = signatureRef.current?.toDataURL() ?? '';
      if (signatureDataURL) {
        const base64Response = await fetch(signatureDataURL);
        const signatureBuffer = await base64Response.arrayBuffer();
        const signatureImage = await pdfDoc.embedPng(signatureBuffer);

        const pages = pdfDoc.getPages();
        const signPage = pages[2];
        signPage.drawImage(signatureImage, {
          x: 120,
          y: t('signature_y_pos'),
          width: 240,
          height: 120,
        });
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, 'facial-treatment-form.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    handleDownloadPdf();
  };

  const nextStep = useCallback(() => {
    if (isLoading) return;

    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(() => {
      setCurrentStep((prev) => prev + 1);
      setIsLoading(false);
    }, 300);
  }, [isLoading]);

  const prevStep = useCallback(() => {
    if (isLoading) return;

    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(() => {
      setCurrentStep((prev) => prev - 1);
      setIsLoading(false);
    }, 300);
  }, [isLoading]);

  return (
    <form onSubmit={handleSubmit} className='form'>
      <h1 className='form__title'>{t('facial_consultation_form_title')}</h1>
      {currentStep === 1 && (<GeneralInfoForm data={generalData} onChange={handleGeneralChange} isLoading={isLoading} />)}
      {currentStep === 2 && (<MedicalConditionsForm data={medicalConditionData} onChange={handleMedicalConditionChange} isLoading={isLoading} />)}
      {currentStep === 3 && (<MedicalConditions2Form data={medicalCondition2Data} onChange={handleMedicalCondition2Change} isLoading={isLoading} />)}
      {currentStep === 4 && (<MedicalConditions3Form data={medicalCondition3Data} onChange={handleMedicalCondition3Change} isLoading={isLoading} />)}
      {currentStep === 5 && (<MedicalConditions4Form data={medicalCondition4Data} onChange={handleMedicalCondition4Change} isLoading={isLoading} />)}
      {currentStep === 6 && (<MedicalConditions5Form data={medicalCondition5Data} onChange={handleMedicalCondition5Change} isLoading={isLoading} />)}
      {currentStep === 7 && (<MedicalConditions6Form data={medicalCondition6Data} onChange={handleMedicalCondition6Change} isLoading={isLoading} />)}
      {currentStep === 8 && (<SignatureForm ref={signatureRef} isLoading={isLoading} />)}

      <div className="form-navigation">
        {currentStep > 1 && (
          <button type="button" onClick={prevStep} className="button back">
            {t('back')}
          </button>
        )}
        {currentStep < 8 && (
          <button type="button" onClick={nextStep} className="button next">
            {t('next')}
          </button>
        )}
        {currentStep === 8 && (
          <button type="submit" className="button submit">
            {t('submit')}
          </button>
        )}
      </div>
    </form>
  )
}

export default FacialTreatmentForm;