import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from '../common/CheckboxGroup';

export default function MedicalConditions3Form({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();
  const skinCareOptions = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('sc_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('medical_conditions_form_title_3')}</h2>
      <CheckboxGroup
        title={t('skin_care_title')}
        options={skinCareOptions}
        selectedValues={data}
        onChange={onChange}
      />
    </div>
  );
}
