import React from 'react';
import CheckboxItem from './CheckboxItem';

function CheckboxGroup({ title, options, selectedValues, onChange }) {
  /**
   * @param options - An array of objects: [{ value: 'mc_acne', label: 'Acne' }, ...]
   * @param selectedValues - An array or object of boolean flags that indicate which ones are checked
   * @param onChange - A handler that toggles the check/uncheck in the parent
   */

  return (
    <div className="checkbox-group">
      {title && <h3 className="checkbox-group__title">{title}</h3>}

      <div className='checkbox-group__list'>
        {options.map((option) => {
          const fieldName = option.value;
          const isChecked = !!selectedValues[option.value];

          return (
            <CheckboxItem
              key={option.value}
              label={option.label}
              name={fieldName}
              checked={isChecked}
              onChange={onChange}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CheckboxGroup;
