import React from 'react';
import CheckboxItem from './CheckboxItem';

function RadioCheckboxGroup({ title, options, selectedValues, onChange }) {
  const handleChange = (e) => {
    const { name } = e.target;

    const updatedEvent = {
      target: { name, type: 'checkbox', checked: true },
    };
    onChange(updatedEvent);

    options.forEach((option) => {
      if (option.value !== name) {
        const deselectEvent = {
          target: { name: option.value, type: 'checkbox', checked: false },
        };
        onChange(deselectEvent);
      }
    });
  };

  return (
    <div className="radio-checkbox-group">
      {title && <h3 className="radio-checkbox-group__title">{title}</h3>}

      <div className="radio-checkbox-group__list">
        {options.map((option) => {
          const fieldName = option.value;
          const isChecked = !!selectedValues[option.value];

          return (
            <CheckboxItem
              key={option.value}
              label={option.label}
              name={fieldName}
              checked={isChecked}
              onChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
}

export default RadioCheckboxGroup;
