import React from 'react';

function CheckboxItem({ label, name, checked, onChange }) {
  return (
    <div className="checkbox-item">
      <label htmlFor={name}>
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <span className="label-text">{label}</span>
      </label>
    </div>
  );
}

export default CheckboxItem;
