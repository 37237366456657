import './bootstrap';
import './src/layout';
import './i18n';
import React from 'react';
import { createRoot } from 'react-dom/client';
import FacialTreatmentForm from './src/components/forms/FacialTreatmentForm';

const div = document.getElementById('facial-treatment-form');
if (div) {
    createRoot(div).render(<FacialTreatmentForm />);
}
