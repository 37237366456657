import React from 'react';
import TextInput from '../common/TextInput';
import DateInput from '../common/DateInput';
import DualCheckboxGroup from '../common/DualCheckboxGroup';
import { useTranslation } from 'react-i18next';

export default function GeneralInfoForm({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('general_form_subtitle')}</h2>

      <div className='form-row'>
        <TextInput
          label={t('name')}
          name="name"
          value={data.name}
          onChange={onChange}
        />

        <DateInput
          label={t('birth_date')}
          name="birth_date"
          value={data.birth_date}
          onChange={onChange}
        />
      </div>

      <TextInput
        label={t('address')}
        name="address"
        value={data.address}
        onChange={onChange}
      />

      <div className='form-row'>
        <TextInput
          label={t('age')}
          name="age"
          value={data.age}
          onChange={onChange}
        />


        <DualCheckboxGroup
          title={t('sex-check-title')}
          option1={{ name: 'sex_woman', label: t('sex_woman'), value: data.sex_woman }}
          option2={{ name: 'sex_man', label: t('sex_man'), value: data.sex_man }}
          onChange={onChange}
        />
      </div>

      <div className='form-row'>
        <TextInput
          label={t('telephone')}
          name="telephone"
          value={data.telephone}
          onChange={onChange}
        />

        <TextInput
          label={t('email')}
          name="email"
          value={data.email}
          onChange={onChange}
        />
      </div>

      <TextInput
        label={t('how_you_found_us')}
        name="how_you_found_us"
        value={data.how_you_found_us}
        onChange={onChange}
      />

      <div className='form-row'>
        <TextInput
          label={t('visit_purpose')}
          name="visit_purpose"
          value={data.visit_purpose}
          onChange={onChange}
        />

        <DateInput
          label={t('date')}
          name="date"
          value={data.date}
          onChange={onChange}
        />
      </div>

      <DualCheckboxGroup
        title={t('subscribe_to_email_list_title')}
        option1={{ name: 'subscribe_to_email_list_yes', label: t('yes'), value: data.subscribe_to_email_list_yes }}
        option2={{ name: 'subscribe_to_email_list_no', label: t('no'), value: data.subscribe_to_email_list_no }}
        onChange={onChange}
      />
    </div>
  );
}