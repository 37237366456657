import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../common/TextInput';
import DualCheckboxGroup from '../common/DualCheckboxGroup';

export default function MedicalConditions6Form({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('medical_conditions_form_title_6')}</h2>

      <DualCheckboxGroup
        title={t('used_acne_or_antibiotics')}
        option1={{ name: 'used_acne_or_antibiotics_no', label: t('no'), value: data.used_acne_or_antibiotics_no }}
        option2={{ name: 'used_acne_or_antibiotics_yes', label: t('yes'), value: data.used_acne_or_antibiotics_yes }}
        onChange={onChange}
      />

      {data.used_acne_or_antibiotics_yes && <div className='form-row'>
        <TextInput
          label={t('used_acne_or_antibiotics_when')}
          name="used_acne_or_antibiotics_when"
          value={data.used_acne_or_antibiotics_when}
          onChange={onChange}
        />

        <TextInput
          label={t('used_acne_or_antibiotics_which')}
          name="used_acne_or_antibiotics_which"
          value={data.used_acne_or_antibiotics_which}
          onChange={onChange}
        />
      </div>}

      <DualCheckboxGroup
        title={t('used_retin_a_or_vitamin_a_products')}
        option1={{ name: 'used_retin_a_or_vitamin_a_products_no', label: t('no'), value: data.used_retin_a_or_vitamin_a_products_no }}
        option2={{ name: 'used_retin_a_or_vitamin_a_products_yes', label: t('yes'), value: data.used_retin_a_or_vitamin_a_products_yes }}
        onChange={onChange}
      />

      {data.used_retin_a_or_vitamin_a_products_yes &&
        <TextInput
          label={t('used_retin_a_or_vitamin_a_products_which')}
          name="used_retin_a_or_vitamin_a_products_which"
          value={data.used_retin_a_or_vitamin_a_products_which}
          onChange={onChange}
        />
      }

      <DualCheckboxGroup
        title={t('had_botox_or_fillers')}
        option1={{ name: 'had_botox_or_fillers_no', label: t('no'), value: data.had_botox_or_fillers_no }}
        option2={{ name: 'had_botox_or_fillers_yes', label: t('yes'), value: data.had_botox_or_fillers_yes }}
        onChange={onChange}
      />

      {data.had_botox_or_fillers_yes &&
        <TextInput
          label={t('had_botox_or_fillers_which')}
          name="had_botox_or_fillers_which"
          value={data.had_botox_or_fillers_which}
          onChange={onChange}
        />
      }
    </div>
  );
}
