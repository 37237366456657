import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';

const SignatureForm = forwardRef((props, parentRef) => {
  const signaturePadRef = useRef();
  const { t, i18n } = useTranslation();

  useImperativeHandle(parentRef, () => ({
    toDataURL: () => {
      return signaturePadRef.current.toDataURL('image/png');
    },
    clear: () => {
      signaturePadRef.current.clear();
    },
    isEmpty: () => {
      return signaturePadRef.current.isEmpty();
    },
  }));

  // Optional "Clear" button inside the child
  const clearSignature = () => {
    signaturePadRef.current.clear();
  };

  return (
    <div className="signature-form">
      <h2 className='form__subtitle'>{t('signature_form_title')}</h2>
      <p className='signature-pad__info'>{t('signature_form_info')}</p>
      <div className="signature-pad__interface">
        <div className="signature-pad__outer">
          <div className='signature-pad__line'></div>
          <div className="signature-pad__inner">
            <SignaturePad ref={signaturePadRef} options={{ maxWidth: 2 }} />
          </div>
        </div>
        <button type="button" onClick={clearSignature} className="button clear-signature">
          {t('signature_form_clear')}
        </button>
      </div>
    </div>
  );
});

export default SignatureForm;
