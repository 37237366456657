import React, { useCallback, useMemo, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import DualCheckboxGroup from '../common/DualCheckboxGroup';
import TextInput from '../common/TextInput';

export default function MedicalConditions2Form({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();

  const handleChange = useCallback(
    (e) => {
      const { name, checked } = e.target;

      if (name === 'allergies_no' && checked) {
        onChange({ target: { name: 'allergies', value: '' } });
      } else if (name === 'recent_surgery_or_cosmetic_no' && checked) {
        onChange({ target: { name: 'recent_surgery_or_cosmetic', value: '' } });
      } else if (name === 'previous_facial_treatments_no' && checked) {
        onChange({ target: { name: 'previous_facial_treatments', value: '' } });
      }

      onChange(e);
    },
    [onChange]
  );

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('medical_conditions_form_title_2')}</h2>
      <DualCheckboxGroup
        title={t('allergies_title')}
        option1={{ name: 'allergies_no', label: t('no'), value: data.allergies_no }}
        option2={{ name: 'allergies_yes', label: t('yes'), value: data.allergies_yes }}
        onChange={handleChange}
      />

      {data.allergies_yes && <TextInput
        label={t('allergies_list_title')}
        name="allergies"
        value={data.allergies}
        onChange={onChange}
      />}

      <TextInput
        label={t('regular_meds_list')}
        name="regular_meds_list"
        value={data.regular_meds_list}
        onChange={onChange}
      />

      <DualCheckboxGroup
        title={t('recent_surgery_or_cosmetic')}
        option1={{ name: 'recent_surgery_or_cosmetic_no', label: t('no'), value: data.recent_surgery_or_cosmetic_no }}
        option2={{ name: 'recent_surgery_or_cosmetic_yes', label: t('yes'), value: data.recent_surgery_or_cosmetic_yes }}
        onChange={handleChange}
      />

      {data.recent_surgery_or_cosmetic_yes && <TextInput
        label={t('recent_surgery_or_cosmetic_list')}
        name="recent_surgery_or_cosmetic"
        value={data.recent_surgery_or_cosmetic}
        onChange={onChange}
      />}

      <DualCheckboxGroup
        title={t('pregnant_or_trying')}
        option1={{ name: 'pregnant_or_trying_no', label: t('no'), value: data.pregnant_or_trying_no }}
        option2={{ name: 'pregnant_or_trying_yes', label: t('yes'), value: data.pregnant_or_trying_yes }}
        onChange={onChange}
      />

      <DualCheckboxGroup
        title={t('previous_facial_treatments')}
        option1={{ name: 'previous_facial_treatments_no', label: t('no'), value: data.previous_facial_treatments_no }}
        option2={{ name: 'previous_facial_treatments_yes', label: t('yes'), value: data.previous_facial_treatments_yes }}
        onChange={handleChange}
      />

      {data.previous_facial_treatments_yes && <TextInput
        label={t('previous_facial_treatments_which')}
        name="previous_facial_treatments"
        value={data.previous_facial_treatments}
        onChange={onChange}
      />}

      <TextInput
        label={t('clinic_visit_goals')}
        name="clinic_visit_goals"
        value={data.clinic_visit_goals}
        onChange={onChange}
      />
    </div>
  );
}
