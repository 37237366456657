import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from '../common/CheckboxGroup';
import TextInput from '../common/TextInput';

export default function MedicalConditions5Form({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();

  const skinTroublesOptions = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('st_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('medical_conditions_form_title_5')}</h2>

      <CheckboxGroup
        title={t('skin_troubles_title')}
        options={skinTroublesOptions}
        selectedValues={data}
        onChange={onChange}
      />

      {data.st_other && <TextInput
        name="other_st_list"
        value={data.other_st_list}
        onChange={onChange}
      />}
    </div>
  );
}
