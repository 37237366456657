import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RadioCheckboxGroup from '../common/RadioCheckboxGroup';
import DualCheckboxGroup from '../common/DualCheckboxGroup';

export default function MedicalConditions4Form({ data, onChange, isLoading }) {
  const { t, i18n } = useTranslation();

  const skinTypeOptions = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('sh_t_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  const skinSpfOptions = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('sh_spf_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  const skinFoundationOptions = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('sh_foundation_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  const skinHealingOptions = useMemo(() => {
    return Object.keys(data)
      .filter((key) => key.startsWith('sh_healing_'))
      .map((value) => ({
        label: t(value),
        value: value,
      }));
  }, [data]);

  return (
    <div className={isLoading ? 'blur' : ''}>
      <h2 className='form__subtitle'>{t('medical_conditions_form_title_4')}</h2>

      <RadioCheckboxGroup
        title={t('title_sh_t')}
        options={skinTypeOptions}
        selectedValues={data}
        onChange={onChange}
      />

      <RadioCheckboxGroup
        title={t('title_sh_spf')}
        options={skinSpfOptions}
        selectedValues={data}
        onChange={onChange}
      />

      <RadioCheckboxGroup
        title={t('title_sh_foundation')}
        options={skinFoundationOptions}
        selectedValues={data}
        onChange={onChange}
      />

      <RadioCheckboxGroup
        title={t('title_sh_healing')}
        options={skinHealingOptions}
        selectedValues={data}
        onChange={onChange}
      />

      <DualCheckboxGroup
        title={t('title_sh_bruising')}
        option1={{ name: 'sh_bruising_no', label: t('no'), value: data.sh_bruising_no }}
        option2={{ name: 'sh_bruising_yes', label: t('yes'), value: data.sh_bruising_yes }}
        onChange={onChange}
      />
    </div>
  );
}
