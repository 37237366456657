import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './src/locales/en/translation.json';
import ukTranslation from './src/locales/uk/translation.json';


i18n
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      uk: {
        translation: ukTranslation,
      },
    },
    fallbackLng: 'en', // Default language if none detected
    detection: {
      order: ['htmlTag'],
    },
    interpolation: {
      escapeValue: false, // React already escapes content by default
    },
  });

export default i18n;
